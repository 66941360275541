import React from 'react'
import EmptyState from '../../components/EmptyState/EmptyState'
import { Link } from 'react-router-dom'
import IconMaintanence from '../../components/GlobalComponents/Icons/IconEmptyState/IconMaintanence'

const Maintanence = () => {
    return (
        <>
          <div className="center-view-box">
          <EmptyState
         title="Prime Updates Incoming!"
         hasIcon={true}
         isClear={false}
         icon={<IconMaintanence/>}
         label="Got it, thanks"
            subTitle={
              <>
               🚧  We’re making some Prime-worthy updates to MDS. Sit tight — we’ll be back faster than a two-day delivery! 🚧<br/><br/>
               Got questions? Drop us a line:<br/>
                <Link to="mailto:appsupport@milliondollarsellers.com" target="_blank">
                appsupport@milliondollarsellers.com
                </Link>
              </>
            }
        />
          </div>
        
        </>
    )
}

export default Maintanence