import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Avtar from "../../../components/Avtar/Avtar";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import Reaction from "./HelperComponets/Reaction";
import IncomingMessageActions from "./HelperComponets/IncomingMessageActions";
import MessageTail from "./HelperComponets/MessageTail";
import OutgoingMessageActions from "./HelperComponets/OutgoingMessageActions";
import IncomingMessageTime from "./HelperComponets/IncomingMessageTime";
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";
import useReactionHandlers from "../Hooks/useReactionHandlers";
import ShowMoreText from "react-show-more-text";
import ReactQuill from "react-quill";

const ChatMessagesOneLine = ({
  menuSelectedDivRef,
  messageInArray = [],
  uniqueId = uuidv4(),
  type = "Channel/Group",
  isOutgoing = false,
  isHighlighted = false,
  isStacked = false,
  hasName = true,
  hasAction = false,
  name = "Jane",
  message = "Hii I am really good.",
  AvatarType = "photo",
  Avtarsrc = "",
  AvtarLetter = "M",
  AvtarIcon = "",
  isRead = true,
  hasBackdrop = false,
  isEdited = false,
  time = "09:12 AM",
  handleEmojiClick,
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,
  actionModel,
  setActionModel,
  handleDelete,
  // reaction
  hasCounter = true,
  hasReactions = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  selectedMessageId,
  emoji1 = "👍",
  emoji2 = "😂",
  emoji3 = "👌",
  emoji4 = "🙌",
  counter = 5,
  setShowDropdown,
  setShowEmojis,
  showEmojis,
  reactionMsg,
  chatId,
  dropDownOptions,
  onSelectOptions,
  chatData,
  handleReactionUsersList,
  isInProgress,
}) => {
  const dropdownRef = useRef(null);
  const [dropDownClass, setDropDownClass] = useState("bottom-drop-down");
  const { hoverEffects, setHoverEffects } = useReactionHandlers();
  const [expanded, setExpanded] = useState(false);

  // Calculate total number of lines
  const totalLines = messageInArray.reduce((total, msg) => {
    return total + Math.ceil(msg.split(" ").length / 10);
  }, 0);

  const manageMouseEnter = () => {
    // setHoverEffect(true);
    setHoverEffects(true);
  };

  const manageMouseLeave = () => {
    if (
      menuSelectedDivRef === null ||
      menuSelectedDivRef.current !== uniqueId
    ) {
      setHoverEffects(false);
    } else if (showEmojis || actionModel) {
      setHoverEffects(true);
    } else {
      setHoverEffects(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let emojiDiv = document.getElementById("REACTION_EMOJIS");
      let allEmojiDiv = document.getElementById("SHOW_ALL_EMOJIS");

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        emojiDiv &&
        !emojiDiv.contains(event.target) &&
        (allEmojiDiv === null ||
          (allEmojiDiv && !allEmojiDiv.contains(event.target))) &&
        setActionModel
      ) {
        setHoverEffects(false);
        setActionModel(false);
        setShowEmojis(false);
        setShowDropdown(false);
        menuSelectedDivRef = null;
      }
    };
    function handleScroll() {
      // setHoverEffects(false);
    }
    const scrollableDiv = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    scrollableDiv.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, [hoverEffects]);

  useEffect(() => {
    if (menuSelectedDivRef.current === null) {
      setHoverEffects(false);
    }
  }, [menuSelectedDivRef.current]);

  // handle select options
  const handleOptionSelect = (selectedOption) => {
    if (onSelectOptions) {
      onSelectOptions(selectedOption, chatData); // Pass chat data as an argument
    }
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const truncateMessage = (textArray, maxWords) => {
    let wordCount = 0;
    const truncatedArray = [];

    for (let i = 0; i < textArray.length; i++) {
      const segment = textArray[i];

      if (segment === "\n") {
        truncatedArray.push(segment); // Preserve newlines
        continue;
      }

      // Count words in the segment
      const words = segment.split(/\s+/);
      if (wordCount + words.length <= maxWords) {
        truncatedArray.push(segment);
        wordCount += words.length;
      } else {
        // Add only the remaining words to the truncated array
        const remainingWords = maxWords - wordCount;
        truncatedArray.push(words.slice(0, remainingWords).join(" ") + "...");
        break;
      }
    }

    return truncatedArray;
  };

  // Helper function to render content with links, mentions, and format
  const renderWithLinksAndFormat = (textArray) => {
    return textArray.map((msg, index) => {
      // Handle mentions with spaces
      if (/^@\S/.test(msg.trim()) && msg.includes(" ")) {
        return (
          <span key={index} className="mention-users">
            {msg}
          </span>
        );
      }
      // Handle links
      else if (/^(https?:\/\/|www\.)[^ "]+$/.test(msg)) {
        return (
          <a
            key={index}
            className="chat-metaLink text-decoration-underline mr-1"
            href={msg}
            target="_blank"
            rel="noopener noreferrer"
          >
            {msg}
          </a>
        );
      }
      // Handle newlines
      else if (msg === "\n") {
        return <br key={index} />;
      }
      // Handle regular text
      else {
        return <span key={index}>{msg}</span>;
      }
    });
  };

  // Main function to render message content
  const renderMessageContent = () => {
    const maxWords = 250; // Limit for truncation
    const totalWords = messageInArray.join("").split(/\s+/).length;
    const hasMoreContent = totalWords > maxWords;

    if (hasMoreContent && !expanded) {
      const truncatedMessages = truncateMessage(messageInArray, maxWords);
      return (
        <>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {renderWithLinksAndFormat(truncatedMessages)}
          </div>
          <button onClick={toggleExpand} className="see-button">
            See more
          </button>
        </>
      );
    } else {
      return (
        <>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {renderWithLinksAndFormat(messageInArray)}
          </div>
          {expanded && (
            <button onClick={toggleExpand} className="see-button">
              See less
            </button>
          )}
        </>
      );
    }
  };

  return (
    <div
      id={uniqueId}
      className="chat-message-bubble-sec"
      onMouseEnter={manageMouseEnter}
      onMouseLeave={manageMouseLeave}
      ref={dropdownRef}
    >
      <div
        className={`chat-message-bubble-inner flex  ${type !== "Personal" ? "chat-channel-group" : ""
          } ${!isOutgoing ? "incoming" : "outgoing"} ${isStacked ? "stacked-item" : ""
          } `}
      >
        {isHighlighted && <div className="highlight"></div>}
        <div
          className={`chat-message-container ${hasReactions ? "reaction-on" : ""
            }`}
        >
          <div className="chat-avtar-message flex items-end">
            <div className="chat-avtar flex">
              {type !== "Personal" && !isOutgoing && (
                <>
                  <Avtar
                    type={AvatarType}
                    src={Avtarsrc}
                    letter={AvtarLetter}
                    icon={AvtarIcon}
                    size="40"
                  />
                </>
              )}
            </div>
            <div className="chat-body flex items-end relative">
              <div className="chat-tail">
                <MessageTail isOutgoing={isOutgoing} />
              </div>
              <div className={`chat-message ${isStacked ? "stacked" : ""}`}>
                <div className="chat-message-text-sec flex flex-col">
                  {type !== "Personal" && !isOutgoing && hasName && (
                    <p className="chat-avtar-name">{name}</p>
                  )}

                  <div className="chat-message-text">
                    {totalLines > 17 ? (
                      <>
                        {renderMessageContent()}
                      </>
                    ) : (
                      messageInArray.map((msg, index) => {
                        if (/^@[^@\s]/.test(msg)) {
                          return (
                            <span key={index} className="mention-users">
                              {msg}
                            </span>
                          );
                        } else if (msg === "\n") {
                          return <br key={index} />; // Handle newlines properly
                        } else {
                          return msg;
                        }
                      })
                    )}
                    <span className="extra-time">
                      {isOutgoing ? (
                        <OutgoingMessageTime
                          isRead={isRead}
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      ) : (
                        <IncomingMessageTime
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      )}
                    </span>
                  </div>
                </div>
                <div className="chat-message-time">
                  {isOutgoing ? (
                    <OutgoingMessageTime
                      isRead={isRead}
                      hasBackdrop={hasBackdrop}
                      isEdited={isEdited}
                      time={time}
                      isInProgress={isInProgress}
                    />
                  ) : (
                    <IncomingMessageTime
                      hasBackdrop={hasBackdrop}
                      isEdited={isEdited}
                      time={time}
                      isInProgress={isInProgress}
                    />
                  )}
                </div>
              </div>
              <div className="chat-actions">
                {!isInProgress && (hasAction || hoverEffects) && isOutgoing ? (
                  <OutgoingMessageActions
                    handleDotsClick={(e) => {
                      menuSelectedDivRef.current = uniqueId;
                      handleDotsClick(e);
                      let heights = window.innerHeight - e.pageY;
                      if (heights < 500) {
                        setDropDownClass("top-drop-down");
                      }
                    }}
                    handleReplyClick={handleReplyClick}
                    handleEmojiClick={(e) => {
                      handleEmojiClick(chatId, e);
                      menuSelectedDivRef.current = uniqueId;
                    }}
                    chatId={chatId}
                  />
                ) : !isInProgress && (hasAction || hoverEffects) ? (
                  <IncomingMessageActions
                    handleDotsClick={(e) => {
                      menuSelectedDivRef.current = uniqueId;
                      handleDotsClick(e);
                      let heights = window.innerHeight - e.pageY;
                      if (heights < 500) {
                        setDropDownClass("top-drop-down");
                      } else {
                        setDropDownClass("bottom-drop-down");
                      }
                    }}
                    handleReplyClick={handleReplyClick}
                    handleEmojiClick={(e) => {
                      handleEmojiClick(chatId, e);
                      menuSelectedDivRef.current = uniqueId;
                    }}
                    chatId={chatId}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {hasReactions && (
            <div className="chat-reaction">
              <Reaction
                isOutgoing={isOutgoing}
                hasCounter={hasCounter}
                counter={counter}
                handleReactionClick={handleReactionClick}
                handleReactionUsersList={handleReactionUsersList}
                hasReactions={hasReactions}
                hasReaction2={hasReaction2}
                hasReaction3={hasReaction3}
                hasReaction4={hasReaction4}
                emoji1={emoji1}
                emoji2={emoji2}
                emoji3={emoji3}
                emoji4={emoji4}
                chatId={chatId}
              />
            </div>
          )}

          <div className={`chat-action-menu ${dropDownClass}`}>
            {actionModel && hoverEffects && (
              <DropDownItem
                size="small"
                options={dropDownOptions}
                isIcon={true}
                onSelect={handleOptionSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatMessagesOneLine.propTypes = {
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  isOutgoing: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  hasTime: PropTypes.bool,
  hasName: PropTypes.bool,
  hasReactions: PropTypes.bool,
  hasAction: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.string,
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,
  isRead: PropTypes.bool,
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  counter: PropTypes.number,
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  reactionMsg: PropTypes.any,
  chatId: PropTypes.array,
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  chatData: PropTypes.object,
  handleReactionUsersList: PropTypes.func,
  isInProgress: PropTypes.bool,
};

export default ChatMessagesOneLine;
