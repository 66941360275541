import * as React from "react"
const IconMaintanence = () => (
  <div className="mds-icons-empty">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M82.667 60c0 12.519-10.149 22.667-22.667 22.667-12.519 0-22.667-10.148-22.667-22.667 0-12.518 10.148-22.667 22.667-22.667 12.518 0 22.667 10.149 22.667 22.667Zm4 0c0 14.728-11.94 26.667-26.667 26.667-14.728 0-26.667-11.94-26.667-26.667 0-14.728 11.94-26.667 26.667-26.667 14.728 0 26.667 11.94 26.667 26.667Zm-18.541 9.567a2.006 2.006 0 0 0 2.744-.696 2.005 2.005 0 0 0-.694-2.744l-9.08-5.414V48.921a1.999 1.999 0 1 0-4 0V61.85c0 .704.374 1.358.976 1.72l10.054 5.998Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
)
export default IconMaintanence