// MessageBarTextField.js
import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBarTextField.css";
import "quill-mention";
import "react-quill/dist/quill.snow.css";

const MessageBarTextField = ({
  placeholder = "Type a message...",
  MentionClickHandle,
  msg,
  setMsg,
  hasMentionDropdown = false,
  onChange,
  handleEnter,
  suggestionList,
  mentionDropDownTopRight,
  defaultValue,
  className,
  editMessage,
  isEmojiOpenSelect,
  setIsEmojiSelect,
  selectedMentionName,
  sendBtnClick,
  setSendBtnClick,
  setHasMentionDropdown,
  editMessageState,
  setEditMessageState,
  editorClear,
  setEditorClear,
  allUsersList,
  uploadErrFlag,
  handleCopyPasteImage,
  mentionedUserIds,
  setMentionedUserIds 
}) => {
  const editor = useRef(null);
  const mentionSelectRef = useRef(null);

  useEffect(() => {
    const atValues = allUsersList.map((user) => ({
      id: user._id,
      value:
        user?.display_name
          ? `${user?.display_name}`
          : "",
      profileImg:
        user?.auth0Id && user.auth0Id !== ""
          ? user.profileImg
          : user.attendeeDetail?.photo,
      onlineStatus: user.onlineStatus,
    }));
    if (editor.current) {
      let quill = new Quill(editor.current, {
        modules: {
          toolbar: false,
          // keyboard: {
          //   bindings: {
          //     // enter: {
          //     //   key: 13,
          //     //   handler: (range, context) => {
          //     //     // quill.focus();
          //     //   },
          //     // },
          //   },
          // },
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            isolateCharacter: true,
            mentionDenotationChars: ["@"],
            positioningStrategy: "fixed",
            dataAttributes: [
              "id",
              "value",
              "denotationChar",
              "link",
              "target",
              "disabled",
              "color",
            ],
            source: async function (searchTerm, renderList, mentionChar) {
              let values;
              if (atValues.length > 0) {
                if (mentionChar === "@") {
                  values = atValues;
                }
                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  const matches = [];
                  const exactMatches = [];
                  const mentionMatches = [];
                  // Loop through values to find matches
                  for (let i = 0; i < values.length; i++) {
                    const valueLowerCase = values[i].value.toLowerCase();
                    const searchTermLowerCase = searchTerm.toLowerCase();
                    // Check for exact match
                    if (valueLowerCase.startsWith(searchTermLowerCase)) {
                      exactMatches.push(values[i]);
                    }
                    // Check for mention match
                    else if (valueLowerCase.includes(searchTermLowerCase)) {
                      mentionMatches.push(values[i]);
                    }
                  }
                  // Push mention matches first
                  matches.push(
                    ...mentionMatches.filter(
                      (match) => !exactMatches.includes(match)
                    )
                  );
                  // Push exact matches to the top
                  matches.unshift(...exactMatches);
                  // Render the list
                  renderList(matches, searchTerm);
                }
              }
            },
            renderItem: (item) => {
              var mentionItem = document.createElement("div");
              mentionItem.className = "mention-user";
              mentionItem.innerHTML = item?.profileImg
                ? '<img src="' +
                item?.profileImg +
                '" class="profile-image" alt="" width="20px" height="20px">' +
                item?.value
                : "<span class='rounded-user-text'>" +
                item?.value?.charAt(0) +
                "</span>" +
                item.value;
              return mentionItem;
            },
            mentionContainer: document.querySelector(".mention-container"),
            defaultMenuOrientation: top,
            onSelect: function (item, insertItem) {
              insertItem(item);
              mentionSelectRef.current = true;
              handleAddMention(item.id);
              handleSelectionChange();
            },
            onClose: function (data) {
              setTimeout(() => {
                mentionSelectRef.current = false;
              }, 200);
            },
          },
        },
        placeholder: "Type a message...",
        formats: ["mention"],
        theme: "snow",
        value: msg,
      });

      quill.on("text-change", () => {
        const contents = quill.getContents();
        const textWithMentions = getTextWithMentions(contents);
        setMsg(textWithMentions);
        onChange(textWithMentions);
      });

      editor.current.quill = quill;
      quill.focus();

      editor.current.addEventListener("paste", handlePaste);
    }
    return () => {
      if (editor.current) {
        editor.current.removeEventListener("paste", handlePaste);
      }
    };
  }, [allUsersList]);

  // Function to handle adding a mention
  const handleAddMention = (id) => {
    setMentionedUserIds((prevIds) => [...new Set([...prevIds, id])]);
  };

  // Function to handle removing a mention
  const handleRemoveMention = (newMentionIds) => {
    setMentionedUserIds((prevIds) =>
      prevIds.filter((id) => newMentionIds.includes(id))
    );
  };

  // // Function to handle paste event
  const handlePaste = (event) => {
    event.preventDefault();
    const quill = editor.current?.quill;
    if (quill) {
      const text = event.clipboardData.getData("text/plain"); 
      const selection = quill.getSelection()?.index || 0; 
      // Insert the pasted text at the current position
      quill.insertText(selection, text, "user");
      setTimeout(() => {
        quill.setSelection(text.length); 
      }, 1000); 
    }
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    const pastedImages = []; // Array to store pasted image data
    let containsImage = false; // Flag to track if pasted content contains an image
    let pastedImageCount = 0;
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        containsImage = true;
        pastedImageCount++;
        const blob = item.getAsFile();
        const imageUrl = URL.createObjectURL(blob);
        pastedImages.push({ imageUrl, blob }); // Push image data into the array
      }
    }
    if (containsImage) {
      event.preventDefault(); // Prevent default paste behavior only for images
    }
    // Process all pasted images together
    handleCopyPasteImage(pastedImages, pastedImageCount);
  };

  useEffect(() => {
    if (editor.current && editor.current.quill) {
      const quill = editor.current?.quill;
      // Handle clearing the editor content and managing userEnterEvent state
      if (sendBtnClick || editorClear || uploadErrFlag) {
        quill.setText("");
        setMsg("");
        setSendBtnClick(false);
        setEditorClear(false);
      }
    }
  }, [allUsersList, sendBtnClick, editorClear, uploadErrFlag])

  useEffect(() => {
    if (editor.current && editor.current.quill) {
      const quill = editor.current.quill;
      if (editMessageState && msg) {
        quill.setText("");
        const updatedDeltaOps = getDeltaWithNamesFromIdsEditMessage(
          msg,
          allUsersList
        );
        // Insert the updated Delta ops into the editor
        quill.updateContents(updatedDeltaOps);

        // Callback to ensure the content is updated before setting focus
        setTimeout(() => {
          // Set the cursor position to the end of the editor
          quill.setSelection(quill.getLength());
          setEditMessageState(false);
        }, 0);
      }

      if (editor.current && isEmojiOpenSelect && editor.current.quill) {
        const quill = editor.current.quill;
        quill.deleteText(0, quill.getLength());
        const UpdateDelta = getDeltaWithNamesFromIdsEmojiSelect(
          msg,
          allUsersList
        );
        quill.updateContents(UpdateDelta);
        handleSelectionChange();
        // Optionally, set the cursor position
        quill.setSelection(quill.getLength());
        setIsEmojiSelect(false);
      }

      if (hasMentionDropdown) {
        addAtSymbol();
        handleSelectionChange();
        setHasMentionDropdown(false);
      }
    }
  }, [sendBtnClick, editorClear, uploadErrFlag, editMessageState, isEmojiOpenSelect, hasMentionDropdown]);

  // Function to get text with mentions
  const getTextWithMentions = (contents) => {
    let textWithMentions = "";
    const currentMentions = contents.ops
      .filter((op) => op.insert.mention)
      .map((op) => op.insert.mention.id);

    // Update mentionedUserIds when mentions are removed
    handleRemoveMention(currentMentions);

    contents.ops.forEach((op, index) => {
      if (op.insert) {

        if (typeof op.insert === "string") {
          // Preserve newlines, don't trim before mentions
          const trimmedText = op.insert.replace(/^\n+/g, ""); // Only remove leading newlines, not trailing ones
          textWithMentions += trimmedText;
        } else if (op.insert.mention) {
          const prevOp = contents.ops[index - 1];

          // Check if previous text ends with a newline, if so, preserve that newline before the mention
          const isPreviousTextWithNewline =
            prevOp && typeof prevOp.insert === "string" && prevOp.insert.endsWith("\n");

          // Only add the newline if the previous content was text and ends with a newline
          if (isPreviousTextWithNewline) {
            textWithMentions += `@${op.insert.mention.id}`;
          } else {
            textWithMentions += `@${op.insert.mention.id}`;
          }
        }
      }
    });

    return textWithMentions;
  };

  const getDeltaWithNamesFromIdsEmojiSelect = (msg, allUsersList) => {
    const deltaOps = [];
    const currentMentionIds = [];
    // const segments = msg.split(" ");
    const segments = msg.match(/@[\w]+|[\u{1F600}-\u{1F64F}]|[^\s]+/gu) || [];
    if (allUsersList) {
      segments.forEach((segment, index) => {
        if (segment.startsWith("@")) {
          const cleanedMention = segment
            .replace(/[^a-fA-F0-9]/g, "")
            .substring(0, 24); // Limit to 24 characters
          const user = allUsersList.find((user) => user._id === cleanedMention);
          if (user) {
            deltaOps.push({
              insert: {
                mention: {
                  index: index * 2 - 1,
                  denotationChar: "@",
                  id: user._id,
                  value:
                    user?.display_name
                      ? `${user?.display_name}`
                      : "",
                },
              },
            });
            deltaOps.push({ insert: " " });
            currentMentionIds.push(user._id);
            handleAddMention(user._id);
          } else {
            deltaOps.push({ insert: `@${cleanedMention}` });
          }
        } else {
          deltaOps.push({ insert: segment });
        }
      });
      handleRemoveMention(currentMentionIds);
    }
    return deltaOps;
  };

  const getDeltaWithNamesFromIdsEditMessage = (msg, allUsersList) => {
    const deltaOps = [];
    const currentMentionIds = [];

    const segments = msg.split(" ");
    if (allUsersList) {
      segments.forEach((segment, index) => {
        if (segment.startsWith("@")) {
          const cleanedMention = segment
            .replace(/[^a-fA-F0-9]/g, "")
            .substring(0, 24); // Limit to 24 characters
          const user = allUsersList.find((user) => user._id === cleanedMention);
          if (user) {
            deltaOps.push({
              insert: {
                mention: {
                  index: index * 2 - 1,
                  denotationChar: "@",
                  id: user._id,
                  value:
                    user?.display_name
                      ? `${user?.display_name}`
                      : "",
                },
              },
            });
            deltaOps.push({ insert: " " });
            currentMentionIds.push(user._id);
            handleAddMention(user._id);
          } else {
            deltaOps.push({ insert: `@${cleanedMention}` });
          }
        } else {
          deltaOps.push({ insert: segment });
          if (segments) {
            deltaOps.push({ insert: " " });
          }
        }
      });
      handleRemoveMention(currentMentionIds);
    }
    return deltaOps;
  };

  const addAtSymbol = async () => {
    const quill = editor.current.quill;
    if (!quill.hasFocus()) {
      quill.focus();
    }
    // Get the current selection range
    const range = quill.getSelection();
    if (range) {
      // Check if "@" is already present in the text
      const currentText = quill.getText();
      const atIndex = currentText.indexOf("@", range.index);

      if (atIndex !== -1) {
        // If "@" is already present, just insert "@" without any changes
        quill.getModule("mention").openMenu("@");
        quill.setSelection(range.index + 1);
      } else {
        if (range.index === 0 || currentText[range.index - 1] === " ") {
          quill.getModule("mention").openMenu("@");
          quill.setSelection(range.index + 1);
        } else {
          if (
            currentText[range.index - 1] !== " " &&
            currentText[range.index - 1] !== undefined
          ) {
            quill.insertText(range.index, " ");
          }
          quill.getModule("mention").openMenu("@");
          quill.setSelection(range.index + 2);
        }
      }
    }
  };

  const handleSelectionChange = (e) => {
    const mentionNodes = editor.current.querySelectorAll(".mention");
    if (mentionNodes.length > 0) {
      mentionNodes.forEach((mentionNode) => {
        mentionNode.classList.add("mention-highlight");
        mentionNode.style.color = "#007be5"; // Change the color to your desired color
      });
    }
  };

  // handle KeyUP
  const handleKeyUp = (e) => {
    if (e && e.target) {
      e.target.focus();
      handleSelectionChange(e);
    }
  };

  // handle KeyDown
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (mentionSelectRef.current) {
        event.preventDefault();
        mentionSelectRef.current = false;
      } else {
        if (event.shiftKey) {
          event.preventDefault();
        } else {
          event.preventDefault(); // Prevents newline character in the text area
          handleEnter(event, true); // Call the handleEnter function
          setMsg("");
        }
      }
    }
  };

  return (
    <div className="messagebarText-sec">
      <div
        className={`messagebarText-container ${className}`}
        id="editor"
        ref={editor}
        onKeyUp={handleKeyUp}
        onFocus={handleSelectionChange} // Handle the selection change when the editor gets focus
        onBlur={handleSelectionChange}
        onKeyDown={handleKeyDown} // Ensure this line is present
        tabIndex={0}
      ></div>
    </div>
  );
};

MessageBarTextField.propTypes = {
  placeholder: PropTypes.string,
  MentionClickHandle: PropTypes.func,
  filteredSuggestionList: PropTypes.array,
  msg: PropTypes.string,
  setMsg: PropTypes.func,
  className: PropTypes.string,
  hasMentionDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  handleEnter: PropTypes.func,
  suggestionList: PropTypes.array,
  selectedNames: PropTypes.array,
  setSelectedNames: PropTypes.func,
};

export default MessageBarTextField;
